@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
.font-inter{
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.size-xxl{
  font-size: xx-large;
}

.size-xl{
  font-size: x-large;
}

.color-red{
  color: #E74C3C;
}

.bgRed{
  background: #E74C3C;

}

.color-white{
  color: white;
}

.color-grey{
  color : #263238;
}

.font-weigh-bold{
  font-weight: bold;
}

.color-grey-grb{
  background:rgb(38, 50, 56) ;
}


.color-yellow{
  color: #FFBB12;
}

.color-green{
  color: #1BB55C;
}

.page{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
